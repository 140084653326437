import React from 'react';
import Layout from '../components/layout/layout';
import photo from '../images/about/photo_de_famille.png';
import logoAfdeaa from '../images/about/logo_afdeaa.png';
import { Container } from '../components/container';
import { Text } from '../components/text';
import { Section } from '../components/section';
import { Heading1, Heading2 } from '../components/headings';
import { BasicCard } from '../components/card';
import { anneMarie, issaSow } from '../data/people';
import { PhoneSvgIcon } from '../icons/phone';
import { MailSvgIcon } from '../icons/mail';

const Person = ({ imageSrc, name, title, email, telephone }) => (
  <div className={`flex items-center space-x-8`}>
    <figure className="flex-shrink-0">
      <img className="h-24 w-24 rounded-full bg-gray-600 shadow-solid inline-block text-green-500" src={imageSrc}
           alt={name}/>
    </figure>
    <div>
      <Text size="lg" weight="bold">
        {name}
      </Text>
      <Text size="lg" weight="medium">
        {title}
      </Text>
      <div className="space-y-1">
        {email ? (
          <a className="block space-x-4" href={`mailto:${email}`}>
            <MailSvgIcon className="h-6 w-6 inline-block text-green-500"/>
            <span>{email}</span>
          </a>
        ) : (
          ''
        )}
        {telephone ? (
          <a className="block space-x-4" href={`tel:${telephone}`}>
            <PhoneSvgIcon className="h-6 w-6 inline-block text-green-500"/>
            <span>{telephone}</span>
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  </div>
);

const AboutPage = () => (
  <Layout title="À Propos de MERERUE">
    <Section>
      <Container maxWidth="4xl" className="px-4 sm:px-8 text-center space-y-4">
        <Heading1>À Propos de MERERUE</Heading1>
        <Text as="p" size="lg">
          La Maison d’Encadrement et de Réinsertion des Enfants de la Rue est une structure qui oeuvre dans
          l'encadrement et la réinsertion
          des enfants vulnérables.
        </Text>
      </Container>
    </Section>

    <Section className="text-lg">
      <Container maxWidth="4xl" className="space-y-8">
        <section className="space-y-4">
          <Heading2>Historique de MERERUE</Heading2>
          <BasicCard className="space-y-6">
            <p>
              MERERUE a été créée par l’Association des Femmes et Jeunes de la Diaspora pour le Développement de
              l’Afrique (AFDEAA). Elle a
              ouvert ses portes officiellement le 17 avril 2005, sur la base d’un bénévolat avec des membres qui ont
              offert le site, la
              construction, l’équipement et leurs compétences.
            </p>
            <figure>
              <img className="d-block mx-auto mw-100" src={photo} alt="Famille"/>
            </figure>
            <p>
              MERERUE entre ainsi dans sa 13e année en octobre 2018, avec un essor d’effectifs qui passent de 30 en
              2005, 40 en 2006 à plus
              de 100 enfants depuis 2016, tous des démunis ou orphelins, handicapés âgés de 3 à 12 ans.
            </p>
            <p>
              Le centre est ouvert aux parents confrontés à la pauvreté, aux professionnels de santé, de la petite
              enfance et du handicap,
              et à toute personne souhaitant s’impliquer en faveur de l’accompagnement d’enfants défavorisés.
            </p>
          </BasicCard>
        </section>

        <section className="space-y-4">
          <Heading2>Présentation de l'AFDEAA</Heading2>
          <BasicCard className="space-y-6">
            <figure>
              <img className="block mx-auto max-w-full" src={logoAfdeaa} alt="AFDEAA"/>
            </figure>

            <div>
              L'Association des Femmes et Jeunes de la Diaspora pour le Développement de l’Afrique (AFDEAA) a pour
              objectif de :
              <ul className="list-disc list-inside ml-3">
                <li>Combattre l’oisiveté, le chômage, la délinquance.</li>
                <li>Lutter contre la pauvreté, la Faim.</li>
                <li>Promouvoir le respect des droits de l'Homme en général et des femmes et enfants en particulier.</li>
                <li>Assister les groupes vulnérables : handicapés, Enfants, personnes du troisième âge.</li>
                <li>Promouvoir la paix et le Développement du Sénégal et d’Afrique.</li>
              </ul>
            </div>
          </BasicCard>
        </section>

        <section className="space-y-4">
          <Heading2>Contacts</Heading2>
          <BasicCard>
            <Person {...anneMarie} />
          </BasicCard>
          <BasicCard>
            <Person {...issaSow} />
          </BasicCard>
        </section>
      </Container>
    </Section>
  </Layout>
);

export default AboutPage;
