import React from 'react';
import PropTypes from 'prop-types';

export const BasicCard = ({ children, className }) => (
  <div className="bg-white overflow-hidden shadow rounded-lg">
    <div className={`px-4 py-5 sm:p-6 h-full ${className}`.trim()}>{children}</div>
  </div>
);

BasicCard.propTypes = {
  children: PropTypes.node.isRequired,
};
